import React from 'react';
import { LoadingButtonProps } from '@mui/lab';
import { TouchButton } from '.';
import { ArrowDropDown } from '@mui/icons-material';

export const TouchPaginationButton = (
  props: LoadingButtonProps & { prev?: boolean }
) => {
  const { prev, children, ...rest } = props;

  return (
    <TouchButton sx={{ width: '50px', p: 0.25 }} {...rest}>
      {children || (
        <ArrowDropDown
          fontSize="large"
          sx={{ ...(prev && { transform: 'rotate(180deg)' }) }}
        />
      )}
    </TouchButton>
  );
};
