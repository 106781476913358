import { createTheme } from '@mui/material';

const customTheme = createTheme({
  typography: {
    allVariants: {
      color: '#263238',
    },
    tooltip: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: '14px',
      letterSpacing: 0,
    },
    helperText: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
    subtitle3: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      extraLight: '#e6f5f3',
      light: '#c0e5e2',
      main: '#4f9a94',
      dark: '#4d7a76',
      contrastText: '#ffffff',
    },
    secondary: {
      extraLight: '#7d8488',
      lighter: '#677074',
      light: '#515b60',
      main: '#263238',
      dark: '#0b0f11',
      contrastText: '#ffffff',
    },
    success: {
      extraLight: '#e6f6ea',
      light: '#99daab',
      main: '#00a32e',
      dark: '#00621c',
      contrastText: '#ffffff',
    },
    warning: {
      extraLight: '#fff7e7',
      light: '#fadd80',
      main: '#f5bb00',
      dark: '#97670a',
      contrastText: '#ffffff',
    },
    accent: {
      extraLight: '#f6e6e6',
      lighter: '#f5c4bc',
      light: '#ff704c',
      main: '#de3b21',
      dark: '#a40000',
      contrastText: '#ffffff',
    },
    light: {
      extraLight: '#f5f5f5',
      lighter: '#f4f4f2',
      light: '#e9ebeb',
      main: '#d4d6d7',
      dark: '#93999c',
      contrastText: '#ffffff',
    },
    error: {
      light: '#a40000',
      main: '#a40000',
      dark: '#a40000',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.light.main,
          color: theme.palette.secondary.main,
        }),
        input: ({ theme }) => ({
          [`&.Mui-disabled`]: {
            WebkitTextFillColor: theme.palette.light.dark,
            [`&~fieldset`]: {
              color: theme.palette.secondary.extraLight,
              borderColor: theme.palette.light.main,
              borderStyle: 'dotted',
            },
          },
          [`&~.MuiOutlinedInput-notchedOutline`]: {
            borderColor: theme.palette.light.main,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.extraLight,
          [`&.Mui-disabled`]: {
            color: theme.palette.light.dark,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.Mui-disabled`]: {
            color: theme.palette.light.dark,
            [`&.MuiButton-contained`]: {
              backgroundColor: theme.palette.light.main,
            },
          },
          [`&.MuiButton-containedSecondary`]: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.light.main,
          },
          [`&.MuiButton-containedSecondary:hover`]: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.light.dark,
          },
          [`&.MuiButton-textPrimary:hover, &.MuiButton-outlinedPrimary:hover`]:
            {
              color: theme.palette.primary.dark,
              backgroundColor: theme.palette.primary.extraLight,
            },
          [`&.MuiButton-outlinedPrimary:hover`]: {
            borderColor: theme.palette.primary.dark,
          },
          [`&.MuiButton-textSecondary:hover, &.MuiButton-outlinedSecondary:hover`]:
            {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.light.lighter,
            },
          [`&.MuiButton-outlinedSecondary:hover`]: {
            borderColor: theme.palette.secondary.extraLight,
          },
          [`&.MuiButton-textAccent:hover, &.MuiButton-outlinedAccent:hover`]: {
            color: theme.palette.accent.dark,
            backgroundColor: theme.palette.accent.extraLight,
          },
          [`&.MuiButton-outlinedAccent:hover`]: {
            borderColor: theme.palette.accent.dark,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 24,
          color: '#263238',
        },
        deleteIcon: {
          fontSize: 20,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.secondary.extraLight,
          },
        }),
      },
    },
  },
});

export default customTheme;
