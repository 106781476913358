import React from 'react';
import { createRoot } from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import './alerts.css';
import './index.css';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from './utils/createEmotionCache';

import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@mui/material';
import App from './components';
import customTheme from './_styled/theme';
import MomentUtils from '@date-io/moment';
import 'moment/locale/ru';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from './contexts/AuthContext';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [/.*tick.*/],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const emotionCache = createEmotionCache();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <CacheProvider value={emotionCache}>
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </CacheProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
