import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import { ToastContainer } from 'react-toastify';
import ModalContainer from 'react-modal-promise';

import { queryClient, router } from 'src/router';
import { Loader } from './MainPage/_shared/page';

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ width: '100%', height: '100%' }}>
        <Suspense fallback={<Loader />}>
          <RouterProvider router={router} fallbackElement={<Loader />} />
        </Suspense>
        <ToastContainer limit={5} />
        <ModalContainer />
      </div>
    </QueryClientProvider>
  );
};

export default App;
