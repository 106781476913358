import { OrderForm } from '../components/MainPage/DispatcherPage/DispatcherOrderForm/types';

import { ReactComponent as DeliveryIcon } from 'src/components/MainPage/_shared/icons/receivingMethods/delivery.svg';
import { ReactComponent as PickupIcon } from 'src/components/MainPage/_shared/icons/receivingMethods/pickup.svg';
import { ReactComponent as AllIcon } from 'src/components/MainPage/_shared/icons/receivingMethods/all.svg';

export const OrderStatusCode = {
  NEW: 0,
  CONFIRM: 10,
  COOKING_WAIT: 20,
  CONFIRM_TIME: 30,
  COOKING: 40,
  PACKING_WAIT: 50,
  PACKING: 60,
  PACKED: 70,
  DELIVERY: 80,
  CANCELED: 90,
  DONE: 100,
};

export const ASAP = 'как можно быстрее';
export const ASAP_SHORT = 'поскорее';
export const NEW_ORDER_ID = 'new';
export const STUB_TEXT = ' ';

export const getStubText = (isHidden: boolean) => (isHidden ? '' : STUB_TEXT);

export const OrderEntity = {
  ITEMS: {
    key: 'products' as EntityKey,
    title: 'Товары',
  },
  PROMOTIONS: {
    key: 'promotions' as EntityKey,
    title: 'Акция',
  },
  PROMOCODES: {
    key: 'promoCodes' as EntityKey,
    title: 'Промокод',
  },
};

export type Entity = typeof OrderEntity[keyof typeof OrderEntity];
export type EntityKey = 'products' | 'promotions';

export const oddMoneyList = [
  { id: 1, value: 0, label: 'Без сдачи' },
  { id: 2, value: 500, label: '500' },
  { id: 3, value: 1000, label: '1000' },
  { id: 4, value: 2000, label: '2000' },
  { id: 5, value: 5000, label: '5000' },
  { id: 6, value: -1, label: 'Другая' },
];

export const Source = {
  DESKTOP: { sourceId: 0, description: 'Сайт' },
  ANDROID: { sourceId: 1, description: 'Android' },
  IOS: { sourceId: 2, description: 'iOS' },
  PHONE: { sourceId: 3, description: 'Телефон' },
};

export const ReceivingMethod = {
  PICKUP: 0,
  DELIVERY: 1,
  IN_HALL: 2,
};

export const PaymentType = {
  COURIER_CARD: 'CourierCard',
  GOOGLE_PAY: 'GooglePay',
  APPLE_PAY: 'ApplePay',
  ONLINE_CARD: 'OnlineCard',
  CASH: 'Cash',
  BARTER: 'Barter',
  LEGAL_ENTITY: 'LegalEntity',
};

export const OnlinePaymentStatus = {
  SUCCESS: 0,
  FAILURE: 1,
  PROCESSING: 2,
  REGISTERED: 3,
};

export const DiscountType = {
  MANUAL: {
    name: 'manualDiscount',
    title: 'Скидка в рублях',
  },
  PERCENTAGE: {
    name: 'discountId',
    title: 'Скидка в %',
  },
};

export const OrderType = {
  DELIVERY: {
    name: ReceivingMethod.DELIVERY,
    title: 'Доставка',
    icon: DeliveryIcon,
  },
  PICKUP: {
    name: ReceivingMethod.PICKUP,
    title: 'Самовывоз',
    icon: PickupIcon,
  },
};

export const OrderTypeExtended = {
  ANY: { name: null, title: 'Любой', icon: AllIcon },
  ...OrderType,
};

const initialStatus = {
  assignmentDateTime: '',
  description: null,
  statusCode: 0,
};

export const initialValues: OrderForm & any = {
  id: NEW_ORDER_ID,
  orderNumber: '',
  status: initialStatus,
  statusHistory: [initialStatus],
  customer: null,
  source: Source.PHONE, // TODO убрать на в4
  sourceId: Source.PHONE.sourceId,
  receivingMethodId: ReceivingMethod.DELIVERY,

  orderComment: '',

  shop: null,
  shopId: '',

  address: null,
  addressId: '',
  isAddressError: false,

  isOddMoneySelect: true,
  oddMoney: '',

  tickets: 0,
  spentTickets: 0,
  totalPrice: 0,
  amountToBePaid: 0,
  accruedTickets: 0,

  payment: null,
  paymentId: null,
  paymentType: null,
  paymentStatus: false,
  paymentTypeId: '',
  paymentTypeValue: '',

  readyTime: ASAP,
  courier: '',

  promoCode: null,
  promotions: [],

  items: [],

  createdAt: '',
  confirmedAt: null,
  waitingTime: null,

  readOnly: false,

  discount: 0,
  promoCodeDiscount: 0,
};

export const touchView = () => window?.location.pathname.includes('cashier');
