import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

type ProtectedRouteProps = {
  roles: string[];
};

const ProtectedRoute = ({ roles = [] }: ProtectedRouteProps) => {
  const { user, auth } = useAuth();
  const location = useLocation();
  const to = user ? '/unauthorized' : '/auth';

  if (user?.role && roles.includes(user.role)) {
    return <Outlet />;
  }

  return <Navigate to={to} state={{ from: location }} replace />;
};

export default ProtectedRoute;
